import React, { useEffect } from "react";
import "./style.css";
import loader from "../../assets/img/loader.gif"

const Preloader = () => {
    const preloader = () => {
        let preload = document.querySelector(".preloader");
        setTimeout(() => {
          preload.style.opacity = "0";
          setTimeout(() => {
            preload.style.display = "none";
          }, 1000);
        }, 3000);
    }

    useEffect(() => {
        preloader();
    },[]);

    return (
        <div className="preloader">
            <div className="spinner_wrap">
                {/* <div className="spinner" /> */}
                <img src={loader} alt="loader"/>
            </div>
        </div>
    )
}

export default Preloader;
