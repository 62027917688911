import React, {lazy} from 'react';
import "./assets/vendor/animate.css/animate.min.css";
import "./assets/vendor/bootstrap/css/bootstrap.min.css";
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/vendor/boxicons/css/boxicons.min.css";
import "./assets/vendor/glightbox/css/glightbox.min.css";
import "./assets/vendor/remixicon/remixicon.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/css/style.css";
import Gallery from './components/Gallery';
const Navbar = lazy(()=>import( './components/Navbar'));
const Header = lazy(()=>import( './components/Header'));
const About = lazy(()=>import( './components/About'));
const Services = lazy(()=>import( './components/Services'));
const Contact = lazy(()=>import( './components/Contact'));
const Clients = lazy(()=>import( './components/Clients'));
const Flow = lazy(()=>import( './components/Flow'));
const Footer = lazy(()=>import( './components/Footer'));
// import './App.css';
// import Preloader from './components/preloader/Preloader';
// import CountDown from './components/countdown/Countdown';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Header/>
      <About/>
      <Clients />
      <Services/>
      <Flow/>
      <Gallery />
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
